<template>
  <div class="w-full md:w-10/12 xl:w-1/2 mx-auto px-5 md:px-0 py-8 shadow-none md:shadow-2xl rounded-3xl bg-white my-16">
    <img class="w-2/3 md:w-2/3 lg:w-1/2 mx-auto" src="../assets/OKAPI-logo-horizontal.png" alt="logo">
    <div v-show="step === 1">
      
<!--      <div class="md:w-10/12 mx-auto mb-6">-->
<!--        <label for="ctos-score" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Borrower CTOS Score</label>-->
<!--        <input type="number" name="ctos-score" id="ctos-score" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"-->
<!--               placeholder="Example: 750" v-model="ctosScore">-->
<!--      </div>-->
      <div class="md:w-10/12 mx-auto mb-6">
        <label for="system-size" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 flex flex-row justify-between">
          <div>System Size (kwp) <span class="text-red-600">*</span></div>
        </label>
        <input type="number" name="system-size" id="system-size" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
               placeholder="Example: 750"
               v-model.trim="$v.systemSize.$model">
        <!-- <select name="system-size" id="system-cost" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
                v-if="!systemSizeEditable"
                v-model.trim="$v.systemSize.$model">
          <option value="0">0</option>
          <option v-for="i in 15" :key="i" :value="i">{{ i }}</option>
        </select> -->
        <div v-if="$v.systemSize.$dirty">
          <div class="text-left text-red-600 italic mt-1" v-if="!$v.systemSize.required">System Size field is required</div>
          <div class="text-left text-red-600 italic mt-1" v-if="!$v.systemSize.decimal">System Size must be a valid number</div>
        </div>
      </div>
      <div class="md:w-10/12 mx-auto mb-6">
        <label for="system-cost" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">System Cost (RM) <span class="text-red-600">*</span></label>
        <input type="number" name="system-cost" id="system-cost"
               v-model.trim="$v.systemCost.$model"
               class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="ex: 100000.00">
        <div v-if="$v.systemCost.$dirty">
          <div class="text-left text-red-600 italic mt-1" v-if="!$v.systemCost.required">System Cost field is required</div>
        </div>
      </div>
      <div class="md:w-10/12 mx-auto mb-6">
        <label for="downpayment" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Downpayment (RM) <span class="text-red-600">*</span></label>
        <input type="number" name="downpayment" id="downpayment" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="ex: 100000.00" v-model.trim="$v.downpayment.$model">
        <div v-if="$v.downpayment.$dirty">
          <div class="text-left text-red-600 italic mt-1" v-if="!$v.downpayment.required">Downpayment field is required</div>
          <!-- decimal -->
          <div class="text-left text-red-600 italic mt-1" v-if="!$v.downpayment.decimal">Downpayment field must be a decimal number</div>
        </div>
      </div>
      <div class="md:w-10/12 mx-auto mb-6">
        <button class="w-full py-4 px-8 bg-blue-500 text-white rounded hover:bg-blue-600" @click="calculateSubmit">Calculate</button>
        <!-- Clear All link button -->
        <a href="#" class="block text-center text-blue-500 mt-4" @click="clearFrom">Clear All</a>
      </div>
    </div>
    <div v-show="step === 2">
      <div class="md:w-10/12 mx-auto mb-6">
        <div class="flex flex-row justify-between">
          <label for="bill-saving" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Estimated Monthly TNB Bill Savings (RM)</label>
          <a href="#" @click.prevent="tnbBillSavingsEditable = !tnbBillSavingsEditable" class="text-xs md:text-sm text-blue-500 font-semibold">{{ (!tnbBillSavingsEditable) ? 'Click to Overwrite' : 'End Overwrite' }}</a>
        </div>
        <input type="number"
               v-model="tnbBillSavings"
               :disabled="!tnbBillSavingsEditable"
               name="bill-saving" id="bill-saving" class="w-full py-4 px-8 bg-yellow-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500">
      </div>
      <div class="md:w-10/12 mx-auto mb-6">
        <label for="instalment" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Estimated Monthly Instalment (RM)</label>
        <input type="number"
               v-model="monthlyPayment"
               name="instalment" id="instalment" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" disabled>
      </div>
      <div class="md:w-10/12 mx-auto mb-6">
        <label for="cashflow" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Estimated Monthly Cash Flow Savings (RM)</label>
        <input type="number"
               :value="monthlyCashflowSavings"
               name="instalment" id="cashflow" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" disabled>
      </div>
      <div class="md:w-10/12 mx-auto mb-6">
        <div class="w-full grid grid-cols-2 gap-2">
          <button class="w-full py-4 px-8 bg-blue-500 text-white rounded hover:bg-blue-600" @click="applyFinanceSubmit">Provide CTOS Consent on-site</button>
          <button class="w-full py-4 px-8 bg-blue-500 text-white rounded hover:bg-blue-600" @click="openModal">Provide CTOS Consent via hyperlink</button>
        </div>
        <!-- button link back to calculator -->
        <a href="#" class="block text-center text-blue-500 mt-4" @click="prevStep">Back to Calculator</a>
      </div>
    </div>
    <!-- Step 3 -->
    <div v-show="step === 3" class="w-full md:w-10/12 mx-auto">
      <div class="grid grid-cols-1 text-right mb-5">
        <p class="text-xs italic">Consent_BPH_V.40_01112020</p>
      </div>
      <div class="grid grid-cols-1 text-left mb-10">
        <div>
          <p class="font-bold">Date: <span class="font-normal">{{ todayDate }}</span></p>
        </div>
      </div>
      <div class="grid grid-cols-1 text-left mb-10">
        <div>
          <p class="font-bold">To:</p>
          <p class="">
            Okapi Technologies Sdn Bhd <br>
            A-3-3, Plaza Bukit Jalil (Aurora Place), <br>
            No. 1, Persiaran Jalil 1, Bandar Bukit Jalil, <br>
            57000, Kuala Lumpur, Malaysia
          </p>
        </div>
        <!--<div class="border-2 px-2 w-full md:w-1/2 lg:w-1/3 h-24">
          <p>{{ this.name }}</p>
        </div>-->
      </div>
      <div class="grid grid-cols-1 text-left mb-5">
        <div>
          <p>Dear Sir,</p>
        </div>
      </div>
      <div class="grid grid-cols-1 text-left mb-5">
        <div>
          <p class="font-bold">Consent Authorisation</p>
        </div>
      </div>

      <div class="grid grid-cols-1 text-left">
        <div>
          <p class="mb-5">Pursuant to the Credit Reporting Agencies (CRA) Act 2010  and Central Bank of Malaysia Act 2009. I/we the undersigned do hereby give my/our consent to you and CTOS System Sdn Bhd ("CTOS"). a registered credit reporting agency under the CRA Act to process my/our company personal data.</p>
          <p class="mb-5">By this consent, I/we understand and agree that:</p>
          <!-- list -->
          <ol class="ml-5" style="list-style-type: lower-roman">
            <li class="mb-5">
              <div class="mb-2">You may conduct credit/trade check including CCRIS checks on me/us and when consent has been given individually, on our directors, shareholders, guarantors, etc. with CTOS at any time for as long as I/we have a trade relationship with you or where any dues remain unpaid and outstanding with you, for any one or more of the following purposes:</div>
              <ul class="list-disc ml-5">
                <li>Opening of account</li>
                <li>Debt recovery</li>
                <li>Credit/Account review</li>
                <li>Credit/Account monitoring</li>
                <li>Credit/Account evaluation</li>
                <li>Legal documentation consequent to a contract or facility granted to you</li>
              </ul>
            </li>
            <li class="mb-5">You may disclose any information on my/our conduct of my/ouraccount(s) with you,to any business entity/ies for bona fide trade checking at any time. I/We am/are also aware and understand that such information will be provided to CTOS, who may in turn share such information to subscribers of their service.</li>
            <li class="mb-5">Where you require any processing of my/our application to be processed by any processing centre located outside Malaysia (including your Head Office), I/we hereby give consent to CTOS to disclose my/our credit information except CCRIS, to such locations outside Malaysia.</li>
            <li class="mb-5">Apart from the above, I/we the undersigned do give my/our consent to you and the CTOS, to process my/our personal data as per the PDPA Act.</li>
          </ol>
        </div>
      </div>

      <div class="grid grid-cols-1 text-left mb-5">
        <p class="mb-2">Signed By</p>
        <VueSignaturePad width="100%" height="300px" ref="signaturePad" class="border-2"/>
        <div class="text-right">
          <button class="text-blue-600 font-bold py-2 px-4 rounded mt-5" @click="undo">Undo Signature</button>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 text-left w-full gap-6 mb-5">
        <!-- form -->
        <div class="mb-5">
          <label for="ic_front" class="block text-sm md:text-lg font-medium text-gray-700 mb-2 text-left">IC Front <span class="text-red-600">*</span></label>
          <input type="file" name="ic_front" id="ic_front" ref="ic_front"
            accept="image/*"
            class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" v-on:change="icFrontChange">
          <div v-if="$v.ic_front.$dirty">
            <div class="text-left text-red-600 italic mt-1" v-if="!$v.ic_front.required">IC Front File is required</div>
          </div>
        </div>
        <div class="mb-5">
          <label for="ic_back" class="block text-sm md:text-lg font-medium text-gray-700 mb-2 text-left">IC Back <span class="text-red-600">*</span></label>
          <input type="file" name="ic_back" id="ic_back" ref="ic_back"
            accept="image/*"
            class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" v-on:change="icBackChange">
          <div v-if="$v.ic_back.$dirty">
            <div class="text-left text-red-600 italic mt-1" v-if="!$v.ic_back.required">IC Back File is required</div>
          </div>
        </div>

        <div class="mb-5">
          <label for="name" class="block text-sm md:text-lg font-medium text-gray-700 mb-2 text-left">Name <span class="text-red-600">*</span></label>
          <input type="text" name="name" id="name" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="Enter your name" v-model.trim="$v.name.$model">
          <div v-if="$v.name.$dirty">
            <div class="text-left text-red-600 italic mt-1" v-if="!$v.name.required">Name field is required</div>
          </div>
        </div>
        <div class="mb-5">
          <label for="bha" class="block text-sm md:text-lg font-medium text-gray-700 mb-2 text-left">Age <span class="text-red-600">*</span></label>
          <select name="bha" id="bha" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" v-model.trim="$v.bha.$model">
            <option></option>
            <option value="1">Under 30 years old</option>
            <option value="2">30 - 40 years old</option>
            <option value="3">40 - 50 years old</option>
            <option value="4">50 - 60 years old</option>
            <option value="5">60 years old and above</option>
          </select>
          <div v-if="$v.bha.$dirty">
            <div class="text-left text-red-600 italic mt-1" v-if="!$v.bha.required">Customer Age field is required</div>
          </div>
        </div>
        <div class="mb-5">
          <label for="ic_number" class="block text-sm md:text-lg font-medium text-gray-700 mb-2 text-left">IC Number <span class="text-red-600">*</span></label>
          <input type="text" name="ic_number" id="ic_number" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="Enter your ic number ex: xxxxxx-xx-xxxx" v-model.trim="$v.ic_number.$model">
          <div v-if="$v.ic_number.$dirty">
            <div class="text-left text-red-600 italic mt-1" v-if="!$v.ic_number.required">IC Number field is required</div>
          </div>
        </div>

      </div>
      <!-- button -->
      <div class="grid grid-cols-1 text-center">
        <button @click.prevent="nextStep" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-8">Submit Consent</button>
        <!-- button link cancel -->
        <a href="#" @click.prevent="() => step = 1" class="text-red-500 hover:text-red-700 text-sm font-bold py-2 px-4 rounded">Cancel Application</a>
      </div>
    </div>
    <!-- Step 4 -->
    <div v-show="step === 4" class="p-6">
      <!-- <div v-if="ctosLoading" class="flex flex-col justify-center items-center h-[250px]">
        <div class="flex flex-col">
          <h2 class="text-xl font-semibold">Checking for CTOS Score ...</h2>
          <p>It might take a minute or two</p>
        </div>
      </div> -->

      <!-- CTOS approved or not-->
      <div class="grid grid-cols-1 text-left" v-if="!ctosLoading">
        <!-- <div v-if="ctosScore" class="bg-green-200 text-lg p-5 text-green-500 font-semibold">
          CTOS Information Successfully Extracted!
        </div>

        <div v-if="ctosScore >= minCtosForApproval" class="my-6 text-left">
          <p class="mb-4">Based on the information extracted from CTOS with your consent, you are <span class="font-bold">indicatively approved</span> for an instalment package with Okapi.</p>
          <p class="mb-4">If you are interested in making a formal application, please confirm your personal details below are accurate, and click on the ‘Apply for Solar Instalment Package’ button below, and a representative from Okapi will be in touch shortly to complete the application process.</p>
          <p class="mb-4">Thank you for your consideration in selecting Okapi as your partner in making your home a greener one.</p>
        </div>

        <div v-if="!ctosScore || ctosScore < minCtosForApproval" class="my-6 text-left">
          <p class="mb-4">Based on the information extracted from CTOS with your consent, we would <span class="font-bold">require further information</span> to provide you with a final decision on your eligibility for our instalment package.</p>
          <p class="mb-4">If you are still interested in making a formal application, please confirm your personal details below are accurate, and click on the ‘Apply for Solar Instalment Package’ button below, and a representative from Okapi will be in touch shortly to complete the application process.</p>
          <p class="mb-4">Thank you for your consideration in selecting Okapi as your partner in making your home a greener one.</p>
        </div> -->

        <!-- Other Information -->
        <div class="my-6">
          <div class="mb-5">
            <label for="bhi" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Customer Monthly Income (RM) <span class="text-red-600">*</span></label>
            <input type="text" name="bhi" id="bhi" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
                  placeholder="Example 10,000" v-model.trim="$v.bhi.$model">
            <div v-if="$v.bhi.$dirty">
              <div class="text-left text-red-600 italic mt-1" v-if="!$v.bhi.required">Customer Income field is required</div>
              <!-- decimal -->
              <div class="text-left text-red-600 italic mt-1" v-if="!$v.bhi.decimal">Customer Income field must be a decimal number</div>
            </div>
          </div>
          <div class="mb-5">
            <label for="designation" class="block text-sm md:text-lg font-medium text-gray-700 mb-2 text-left">Designation <span class="text-red-600">*</span></label>
            <input type="text" name="designation" id="designation" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="Enter your designation" v-model.trim="$v.designation.$model">
            <div v-if="$v.designation.$dirty">
              <div class="text-left text-red-600 italic mt-1" v-if="!$v.designation.required">Designation field is required</div>
            </div>
          </div>
          <div class="mb-5">
            <label for="phone_no" class="block text-sm md:text-lg font-medium text-gray-700 mb-2 text-left">Mobile number <span class="text-red-600">*</span></label>
            <input type="tel" name="phone_no" id="phone_no" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="Enter your mobile number" v-model.trim="$v.phone_no.$model">
            <div v-if="$v.phone_no.$dirty">
              <div class="text-left text-red-600 italic mt-1" v-if="!$v.phone_no.required">Mobile number field is required</div>
            </div>
          </div>
          <div class="mb-5">
            <label for="email" class="block text-sm md:text-lg font-medium text-gray-700 mb-2 text-left">Email Address <span class="text-red-600">*</span></label>
            <input type="email" name="email" id="email" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
                  placeholder="Enter your Emai Address"
                  v-model.trim="$v.email.$model">
            <div v-if="$v.email.$dirty">
              <div class="text-left text-red-600 italic mt-1" v-if="!$v.email.required">Email Address field is required</div>
              <div class="text-left text-red-600 italic mt-1" v-if="!$v.email.email">Email Address field must be a valid Email (eg. john@gmail.com)</div>
            </div>
          </div>
          <hr class="mb-6">
          <div class="md:w-full mx-auto mb-6">
            <label for="address_line" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Address Line 1 <span class="text-red-600">*</span></label>
            <input type="text" name="address_line" id="address_line" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="ex: 123, Jalan 1, Taman 1, 12345, Kuala Lumpur" v-model.trim="$v.address_line.$model">
            <div v-if="$v.address_line.$dirty">
              <div class="text-left text-red-600 italic mt-1" v-if="!$v.address_line.required">Address Line field is required</div>
            </div>
          </div>
          <div class="md:w-full mx-auto mb-6">
            <label for="address_line_2" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Address Line 2</label>
            <input type="text" name="address_line_2" id="address_line_2" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" v-model="address_line_2">
          </div>
          <div class="md:w-full mx-auto">
            <div class="mb-6 w-full md:w-1/2 inline-block md:pr-5">
              <label for="postcode" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Postcode <span class="text-red-600">*</span></label>
              <input type="text" name="postcode" id="postcode" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="ex: 47500" v-model.trim="$v.postcode.$model">
              <div v-if="$v.postcode.$dirty">
                <div class="text-left text-red-600 italic mt-1" v-if="!$v.postcode.required">Postcode field is required</div>
                <!-- integer -->
                <div class="text-left text-red-600 italic mt-1" v-if="!$v.postcode.integer">Postcode field must be a integer number</div>
              </div>
            </div>
            <div class="mb-6 w-full md:w-1/2 inline-block">
              <label for="city" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">City <span class="text-red-600">*</span></label>
              <input type="text" name="city" id="city" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" v-model.trim="$v.city.$model">
              <div v-if="$v.city.$dirty">
                <div class="text-left text-red-600 italic mt-1" v-if="!$v.city.required">City field is required</div>
              </div>
            </div>
          </div>
          <div class="md:w-full mx-auto mb-6">
            <label for="state" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">State <span class="text-red-600">*</span></label>
            <select name="state" id="state" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" v-model.trim="$v.state.$model">
              <option value="Johor">Johor</option>
              <option value="Kedah">Kedah</option>
              <option value="Kelantan">Kelantan</option>
              <option value="Melaka">Melaka</option>
              <option value="Negeri Sembilan">Negeri Sembilan</option>
              <option value="Pahang">Pahang</option>
              <option value="Perak">Perak</option>
              <option value="Perlis">Perlis</option>
              <option value="Pulau Pinang">Pulau Pinang</option>
              <option value="Sabah">Sabah</option>
              <option value="Sarawak">Sarawak</option>
              <option value="Selangor">Selangor</option>
              <option value="Terengganu">Terengganu</option>
              <option value="W.P. Kuala Lumpur">W.P. Kuala Lumpur</option>
              <option value="W.P. Labuan">W.P. Labuan</option>
              <option value="W.P. Putrajaya">W.P. Putrajaya</option>
            </select>
            <div v-if="$v.state.$dirty">
              <div class="text-left text-red-600 italic mt-1" v-if="!$v.state.required">State field is required</div>
            </div>
          </div>
        </div><!--End of Other Information-->

        <div class="bg-gray-50 p-5 my-6">
          <h3 class="text-lg font-semibold">Your Information</h3>
          <!-- Customer info table summary -->
          <table class="mt-4">
            <tr>
              <td class="font-semibold pr-3">Name</td>
              <td>{{ name }}</td>
            </tr>
            <tr>
              <td class="font-semibold pr-3">IC Number</td>
              <td>{{ ic_number }}</td>
            </tr>
            <tr>
              <td class="font-semibold pr-3">Designation</td>
              <td>{{ designation }}</td>
            </tr>
            <tr>
              <td class="font-semibold pr-3">Email Address</td>
              <td>{{ email }}</td>
            </tr>
            <tr>
              <td class="font-semibold pr-3">Phone No.</td>
              <td>{{ phone_no }}</td>
            </tr>
            <tr>
              <td class="font-semibold pr-3">Address</td>
              <td>
                {{ address_line }} <br/>
                {{ address_line_2 }} <br/>
                {{ postcode }} <br/>
                {{ city }} <br/>
                {{ state }} <br/>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- FINAL SUBMISSION -->
      <div class="md:w-10/12 mx-auto mb-6" v-if="!ctosLoading">
        <button @click.prevent="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-8">Apply for Solar Instalment Package</button>
        <!-- button link cancel -->
        <a href="#" @click.prevent="() => step = 1" class="text-red-500 hover:text-red-700 text-sm font-bold py-2 px-4 rounded">Cancel Application</a>
      </div>
    </div>
    <div v-show="step === 5" class="p-6">
      <div class="flex flex-col justify-center items-center h-[350px]">
        <div class="flex flex-col">
          <h2 class="text-2xl font-semibold text-green-600">Successfully submitted</h2>
          <h2 class="text-xl font-semibold">Thank you for your application!</h2>
          <a href="#" @click.prevent="() => step = 1" class="my-6">Back to First Page</a>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div v-if="showModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div >
                <div class="mt-3 text-center sm:mt-0 sm:text-left">
                  <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Provide CTOS Consent via hyperlink</h3>
                  <form>
                    <div class="my-6">
                      <label for="customerName" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Name <span class="text-red-600">*</span></label>
                      <input type="text" v-model="customerName" required name="customerName" id="customerName" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="Enter name">
                      <div v-if="$v.customerName.$dirty">
                        <div class="text-left text-red-600 italic mt-1" v-if="!$v.customerName.required">Name field is required</div>
                      </div>
                    </div>
                    <div class="my-6">
                      <label for="customerEmail" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 text-left">Email <span class="text-red-600">*</span></label>
                      <input type="text" v-model="customerEmail" required name="customerEmail" id="customerEmail" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="Enter email to receive link">
                      <div v-if="$v.customerEmail.$dirty">
                        <div class="text-left text-red-600 italic mt-1" v-if="!$v.customerEmail.required">Email address field is required</div>
                        <div class="text-left text-red-600 italic mt-1" v-if="!$v.customerEmail.email">Email Address field must be a valid Email (eg. john@gmail.com)</div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button @click.prevent="submitCTOSConsentViaHyperlink" :disabled="isLoading" type="button" class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-500 hover:bg-blue-700 sm:ml-3 sm:w-auto">Submit</button>
              <button @click="closeModal" :disabled="isLoading" type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { required, decimal, integer, email } from 'vuelidate/lib/validators'
import moment from 'moment'
export default {
  name: "FormView",
  metaInfo() {
    return {
      title: "Okapi - Solar Financing",
      meta: [],
    };
  },
  async created() {
    // get winddow url ?key=abc15
    // api -> backend to get quote data and pre fill form
    // skip step 3
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key');
     if(key){
      await this.autoLogin(key)
      //call api to get quote data
      console.log(key)
      this.hyperlink_key = key
      const apiUrl = '/api/quote/form?key=' + key;
      console.log(apiUrl)

      this.$http.get(apiUrl).then(response => {
        const quoteData = response.data;
        this.name = quoteData.data.customer_name;
        this.email = quoteData.data.customer_email;
        this.customerName = quoteData.data.customer_name;
        this.customerEmail = quoteData.data.customer_email;
        this.step = 3;

        this.$nextTick(() =>{
          this.$refs.signaturePad.resizeCanvas() // need resize because v-show hides the element the canvas is not drawn
        })
      }).catch(error => {
        console.error(error);
        this.$toast.error('Unable to get the quote. Please try again later.')
      });
    }

    // get latest settings from backend
    this.$http.get('/api/settings').then(response => {
      this.tnbTiers = JSON.parse(response.data.tnbTiers)
      this.kwtbb_percent = response.data.kwtbb_percent
      this.kwptokwh_ratio = response.data.kwptokwh_ratio
      this.eirpa = response.data.eirpa
      this.sst_percent = response.data.sst_percent
      this.minCtosForApproval = parseFloat(response.data.min_ctos_score)
    })
  },
  data() {
    return {
      ctosLoading: false,
      validatingIc: false,
      minCtosForApproval: 700,
      todayDate: null,
      isTnbBillSavings: false,
      sst_percent: 6,
      kwtbb_percent: 1.6,
      kwptokwh_ratio: 111.630036630037,
      eirpa: 8, // interest per annum
      tnbTiers: [
        {
          tier: 200,
          rmkwh: 0.218,
          cumulative: 43.6
        },
        {
          tier: 300,
          rmkwh: 0.334,
          cumulative: 77
        },
        {
          tier: 600,
          rmkwh: 0.516,
          cumulative: 231.8
        },
        {
          tier: 900,
          rmkwh: 0.546,
          cumulative: 395.6
        },
        {
          tier: 1000000,
          rmkwh: 0.571,
          cumulative: 10000000
        }
      ],
      step: 1,
      // form data
      bhi: "",
      bha: "",
      ctosScore: "",
      systemSizeEditable: false,
      systemSize: 0,
      systemCost: 0,
      downpayment: 0,

      // estimates
      tnbBillSavings: 0,
      tnbBillSavingsEditable: false,

      monthlyPayment: 0,

      // form data 2
      address_line: "",
      address_line_2: "",
      postcode: "",
      city: "",
      state: "",

      //form data 3
      name: "",
      ic_number: "",
      ic_front: null,
      ic_back: null,
      designation: "",
      phone_no: "",
      email: "",

      // Provide CTOS Consent via hyperlink
      showModal: false,
      customerName: "",
      customerEmail: "",
      isLoading: false,
      hyperlink_key: null,
    };
  },
  validations: {
    bhi: {
      required,
      decimal
    },
    bha: {
      required,
    },
    systemSize: {
      required,
      decimal
    },
    systemCost: {
      required,
    },
    downpayment: {
      required,
      decimal
    },
    address_line: {
      required,
    },
    postcode: {
      required,
      integer
    },
    city: {
      required,
    },
    state: {
      required,
    },
    name: {
      required,
    },
    ic_number: {
      required,
    },
    ic_front: {
      required,
    },
    ic_back: {
      required,
    },
    designation: {
      required,
    },
    phone_no: {
      required,
    },
    email: {
      required, email
    },
    customerName: {
      required,
    },
    customerEmail: {
      required, email
    }
  },
  mounted() {
    this.todayDate = new moment().format("DD/MM/YYYY");
  },
  computed: {
    monthlyKwh() {
      return parseFloat(this.systemSize) * this.kwptokwh_ratio;
    },
    tariffTierIndex() {
      if (this.systemSize > 0) {
        // find monthlyKwh is between which tier
        let tierIndex = this.tnbTiers.findIndex(
          (tier) => this.monthlyKwh <= tier.tier
        );
        if (tierIndex > 0) {
          return tierIndex - 1;
        } else {
          return tierIndex;
        }
      }
      return false
    },
    monthlyCashflowSavings() {
      if (isNaN(this.tnbBillSavings)) return 0
      return parseFloat(this.tnbBillSavings - this.monthlyPayment).toFixed(2)
    }
  },
  watch: {
    systemSize() {
      if (this.systemSize > 0) {
        if (this.tariffTierIndex && this.monthlyKwh) {
          let precedingTierCumulative = parseInt(this.tariffTierIndex) === 0 ? this.tnbTiers[this.tariffTierIndex].cumulative : this.tnbTiers[this.tariffTierIndex - 1].cumulative;
          let subsequentTierRmkwh = (this.tnbTiers[this.tariffTierIndex + 1]) ? this.tnbTiers[this.tariffTierIndex + 1].rmkwh : this.tnbTiers[this.tariffTierIndex].rmkwh;
          let serviceFees = ((parseFloat((this.sst_percent + this.kwtbb_percent)) / 100) + 1)

          console.log((this.tnbTiers[this.tariffTierIndex].cumulative
              + ((this.monthlyKwh - this.tnbTiers[this.tariffTierIndex].tier) * subsequentTierRmkwh)))

          console.log((precedingTierCumulative * (this.sst_percent / 100)))

          this.tnbBillSavings = parseFloat((this.tnbTiers[this.tariffTierIndex].cumulative
                  + ((this.monthlyKwh - this.tnbTiers[this.tariffTierIndex].tier) * subsequentTierRmkwh))
              * serviceFees).toFixed(2)
        }
      }
    },
    downpayment() {
      if (this.downpayment > 0) {
        console.log('EIRPA', this.eirpa);

        const financeAmount = this.systemCost - this.downpayment
        this.monthlyPayment = parseFloat(this.calculateAnnuity(10, this.eirpa, financeAmount)).toFixed(2);
      }
    },
    systemCost() {
      if (this.systemCost > 0) {
        console.log('EIRPA', this.eirpa);

        const financeAmount = this.systemCost - this.downpayment
        this.monthlyPayment = parseFloat(this.calculateAnnuity(10, this.eirpa, financeAmount)).toFixed(2);
      }
    },
    ic_number () {
      // check if ic_number has dashes, remove it
      if (this.ic_number.includes('-')) {
        this.ic_number = this.ic_number.replace('-', '')
      }
    }
  },
  methods: {
    calculateSubmit() {
    
      this.$v.systemSize.$touch();
      this.$v.systemCost.$touch();
      this.$v.downpayment.$touch();

      if (this.$v.systemSize.$error || this.$v.systemCost.$error || this.$v.downpayment.$error) {
        return;
      }
      this.nextStep()
    },
    applyFinanceSubmit() {
      this.nextStep()
    },
    async nextStep() {

      // validate ic form
      if (this.step == 3) {
        this.$v.ic_front.$touch();
        this.$v.ic_back.$touch();
        this.$v.name.$touch();
        this.$v.ic_number.$touch();
        this.$v.bha.$touch();

        if (this.$v.ic_front.$error || this.$v.ic_back.$error || this.$v.name.$error || this.$v.ic_number.$error || this.$v.bha.$error) {
          return;
        }
      }

      this.step++;

      this.$nextTick(() =>{
        if (this.step === 3) { // signatory page
          this.$refs.signaturePad.resizeCanvas() // need resize because v-show hides the element the canvas is not drawn
        }
      })
      
      if (this.step === 4) {
        // this.checkCtos() // trigger ctos check
      }
      window.scrollTo(0, 0);
    },
    prevStep() {
      this.step--;
      window.scrollTo(0, 0);
    },
    async submit() {
      this.customerName = this.name;
      this.customerEmail = this.email;
      
      this.$v.$touch()
      if (this.$v.$error) {
        this.$nextTick(() => {
          let domRect = document.querySelector('.text-red-600').getBoundingClientRect();
          window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop
          );
        })
        return false
      }

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      let form =  {
        bhi: this.bhi,
        bha: this.bha,
        ctosScore: this.ctosScore,
        systemSize: this.systemSize,
        systemCost: this.systemCost,
        downpayment: this.downpayment,
        tnbBillSavings: this.tnbBillSavings,
        monthlyPayment: this.monthlyPayment,
        address_line: this.address_line,
        address_line_2: this.address_line_2,
        postcode: this.postcode,
        city: this.city,
        state: this.state,
        name: this.name,
        ic_number: this.ic_number,
        ic_front: this.ic_front,
        ic_back: this.ic_back,
        designation: this.designation,
        phone_no: this.phone_no,
        email: this.email,
        signature: (!isEmpty) ? data : null,
        hyperlink_key: this.hyperlink_key? this.hyperlink_key : null
      }

      // convert to formData object
      let formData = new FormData();
      Object.keys(form).forEach((key) => {
        formData.append(key, form[key]);
      })

      this.$http.post('/api/quotes', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.success) {
          this.$toast.success('Successfully submitted')
          this.nextStep() // go to step 5
          // this.$router.push({ name: 'home'})

        }
      }).catch(() => {
        this.$toast.error('Something went wrong while submitting')
      })
    },
    icFrontChange(e) {
      this.ic_front = e.target.files[0]
      this.icOcr(this.ic_front)
    },
    icBackChange(e) {
      this.ic_back = e.target.files[0]
      this.icOcr(this.ic_back, true)
    },
    // handles ic OCR
    icOcr(file, checkIsBack = false) {
      if (file) {
        let formData = new FormData();
        // append file to formdata
        formData.append('ic_file', file);
        this.$http.post('/api/ic_ocr', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.data.success && res.data.ic.length > 0) {
              if (checkIsBack && res.data.is_front_ic) {
                // if check if back is marked, and the data returned is front
                this.$swal({
                  icon: 'warning',
                  title: 'Oops...',
                  text: 'Looks like you uploaded an invalid IC, try again upload with a valid IC.',
                });
                // // clear ic file from input
                // this.ic_back = null
                // this.$refs.ic_back.value = null
              }

              // if front ic then use
              if (res.data.is_front_ic) {
                this.name = res.data.name
                this.ic_number = res.data.ic.replace(/-/g, '');
              }

              // set age 
              if (res.data.age < 30) {
                this.bha = 1
              } else if (res.data.age >= 30 && res.data.age <= 40) {
                this.bha = 2
              } else if (res.data.age >= 41 && res.data.age <= 50) {
                this.bha = 3
              } else if (res.data.age >= 51 && res.data.age <= 60) {
                this.bha = 4
              } else if (res.data.age > 60) {
                this.bha = 5
              }
          } else {
            this.$swal({
              icon: 'warning',
              title: 'Oops...',
              text: 'Looks like you uploaded an invalid IC, you can still proceed',
            });
          }
        })
      }
    },

    // checkCtos() {
    //   // ctos loading
    //   this.ctosLoading = true;
    //   this.$http.post('/api/quotes/ctos', {
    //     name: this.name,
    //     ic_number: this.ic_number
    //   }).then(res => {
    //     if (res.data.success) {
    //       this.ctosScore = parseFloat(res.data.ctos_score)
    //     }
    //   }).finally(() => {
    //     this.ctosLoading = false
    //   })
    // },
    clearFrom() {
      this.bhi = "";
      this.bha = "";
      this.ctosScore = "";
      this.systemSize = "";
      this.systemCost = "";
      this.downpayment = "";
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    calculateAnnuity(years, interest, amount) {
      // Get monthly interest
      const i = interest / 100 / 12;
      let pow = i + 1;
      const n = years * 12;
      pow = Math.pow(pow, -n);
      const annuity = (i / (1 - pow)) * amount;
      return annuity;
    },
    editTnbSavings () {
      this.isTnbBillSavings = !this.isTnbBillSavings
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.customerName = "";
      this.customerEmail = "";
      this.$v.$reset();
    },
    submitCTOSConsentViaHyperlink() {
      // validate modal form
      this.$v.customerName.$touch();
      this.$v.customerEmail.$touch();

      if (this.$v.customerName.$error || this.$v.customerEmail.$error) {
        return;
      }

      this.isLoading = true;

      this.$http.post('/api/quotes/sendlink', {
        systemSize: this.systemSize,
        systemCost: this.systemCost,
        downpayment: this.downpayment,
        tnbBillSavings: this.tnbBillSavings,
        monthlyPayment: this.monthlyPayment,
        name: this.customerName,
        email: this.customerEmail
      }).then(res => {
        if (res.data.success) {
          this.isLoading = false;
          this.$toast.success('CTOS Consent hyperlink Sent')
          this.closeModal()
        }
      }).catch(() => {
        this.isLoading = false;
        this.$toast.error('Something went wrong while creating CTOS Consent hyperlink')
      })
    },
    autoLogin(key) {
      this.$http.post('/api/auto-login', {
        hyperlink_key: key
      }).then(res => {
        if (res.data.access_token) {
          localStorage.setItem('accessToken', res.data.access_token); // to store the token
          this.$store.dispatch('auth/loginUser').then(() => {
            // console.log('check auth')
            // console.log(this.$store.state.auth.user)
            // console.log(this.$store.state.auth.authenticated)
            // this.$router.push({ name: 'quote'})
          })
        }
      }).catch(() => {
        this.$toast.error('Something went wrong while logging in')
      })
    }
  },
}
</script>

<style scoped>

</style>
