import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "@/views/LoginView";
import FormView from "@/views/FormView";
import HomeView from "@/views/HomeView";
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      middleware: 'guest',
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      middleware: 'auth',
    }
  },

  {
    path: '/quote',
    name: 'quote',
    component: FormView,
    meta: {
      middleware: 'auth',
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})


router.beforeEach((to, from, next) => {
  if (to.meta.middleware === "guest") {
    if(store.state.auth.authenticated) {
      next({name: 'home'})
    }
    next()
  } else{
    if (store.state.auth.authenticated || to.query.key) {
      next()
    } else {
      next({name: 'login'})
    }
  }
})

export default router
