<template>
  <div class="home">
    <div class="container mx-auto h-screen grid content-center">
      <div class="flex flex-col justify-center items-center mt-12">
        <router-link :to="{ name: 'quote'}"
                     class="w-1/2 m-auto mx-auto py-4 bg-primary w-full rounded text-blue-50 font-bold hover:bg-blue-700">
          Create a new Quote
        </router-link>
        <a href="#" class="m-auto py-4 text-blue-500 font-semibold"
           @click.prevent="logout">
          Log Out
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'HomeView',
  metaInfo() {
    return {
      title: "Okapi - Solar Financing",
      meta: [],
    };
  },
  components: {
  },
  created () {
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
